// config.js
const config = {
    // apiUrl: 'http://localhost:3001',
    apiUrl: 'https://cargo-2711.kz:3001',
   
    // nameCargo: 'TURKESTAN KARGO'
    nameCargo: 'CARGO_2711'
  };
  
  
export default config;
